import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { appData, ga } from "../../../../config";

import logo from '../../../../images/ProductsLogo/DarkBg/Frame.svg'
import toast from "react-hot-toast";

import PasswordChecklist from "react-password-checklist"
import valid from '../../../../images/check (4).png';
import invalid from '../../../../images/close (3).png';

function ResetPassword({ submit, app, loading, setLoading, redirect = false, toggle, open, setActive, token }) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({

    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Please Enter Your password'),
      confirm_password: Yup.string().required('Please Enter Your Confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match')

    })
    ,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setLoading(true)
        // convert email and password to string
        const sendData = {
          password: values.password,
          token: token
        };

        // Call the register method from the authService
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(sendData),
        };

        fetch("https://api.grandautomation.io/api/update-password", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log(data, "data");
            // console.log(data.data.status, "token");

            if (data.success) {
              toast.success("Password Updated Successfully")
              setLoading(false)
              setActive('Login')



            } else {
              toast.error(data.error.message)
            }
          });


        // Reset the form after successful submission
      } catch (error) {
        setLoading(false)
        console.error('Error:', error);
      } finally {
        // Set submitting state to false after submission
        setSubmitting(false);
      }
    },
  })


  async function submit (values, { resetForm, setSubmitting }) {

    try {
      setLoading(true)
      // convert email and password to string
      const sendData = {
        password: values.password,
        token: token
      };

      // Call the register method from the authService
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sendData),
      };

      fetch("https://api.grandautomation.io/api/update-password", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data, "data");
          // console.log(data.data.status, "token");

          if (data.success) {
            toast.success("Password Updated Successfully")
            setLoading(false)
            setActive('Login')



          } else {
            toast.error(data.error.message)
          }
        });


      // Reset the form after successful submission
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    } finally {
      // Set submitting state to false after submission
      setSubmitting(false);
    }

  }

  return (

    <div className=" flex items-center justify-center flex-1 ">
      <div className="">
        <iframe id="targetIframe" style={{ display: "none" }}></iframe>
        {/* 
        {error && (
          <div
            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">{error}</span>
          </div>
        )}
        {success && (
          <div
            className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span class="font-medium">{success}</span>
          </div>
        )} */}
        <form onSubmit={formik.handleSubmit} className="text-white formWidth m-auto resetPassword">

          <div className="d-flex justify-content-sm-between justify-content-center align-items-center mb-5">

            <h5 className="m-0 text-center">Reset Password</h5>

            <div
              className="d-sm-flex d-none"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "10rem" }}
                className="logoResponsive"
              />
            </div>



          </div>


          <div className="">
            <div className="w-full mt-10">
              <div className="flex">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 me-1"
                >
                  New Password
                </label>
                <span className="starColor">*</span>
              </div>
              <div className="mt-2 relative">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={formik.errors.password && formik.touched.password ? "loginInputsInValid" : "loginInputsValid"}
                  placeholder="Enter Your password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              {formik.errors.password && formik.touched.password ? (
                <div
                  style={{
                    marginTop: "0.2rem",
                    color: "#f80000",
                    fontSize: "0.875rem",
                  }}
                >
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-4">
            <div className="flex">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 me-1"
              >
                Confirm New Password
              </label>
              <span className="starColor">*</span>
            </div>

            <div className="my-2 relative">
              <input
                id="password"
                name="confirm_password"
                type="password"
                autoComplete="current-password"
                className={formik.errors.password && formik.touched.password ? "loginInputsInValid" : "loginInputsValid"}
                placeholder="Enter Confirm Password Here"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {formik.errors.confirm_password && formik.touched.confirm_password ? (
              <div
                style={{
                  marginTop: "0.2rem",
                  color: "#f80000",
                  fontSize: "0.875rem",
                }}
              >
                {formik.errors.confirm_password}
              </div>
            ) : null}

            <div className=' text-sm text-start md:items-center items-start md:pt-10 pt-0 my-3 flex flex-col justify-center'>
              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "match"]}
                minLength={5}
                value={formik.values.password}
                valueAgain={formik.values.confirm_password}
                onChange={(isValid) => { }}
                className=''
                iconComponents={{ ValidIcon: <img src={valid} width={10} className='me-3 mt-2' />, InvalidIcon: <img src={invalid} width={10} className='me-3 mt-2' /> }}
              />
            </div>

            <div className="flex items-center mt-4">
              <button
                disabled={loading}
                type="submit"
                style={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#4c4c4c",
                  borderRadius: "0.5rem",
                  fontSize: "0.875rem",
                  padding: "0.625rem",
                  textAlign: "center",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>

  );
}

export default ResetPassword;