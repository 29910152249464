import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavigateLoader from "./Navbar/Modals/Login/NavigateLoader";
import { useContext, useEffect, useState } from "react";
import { userIsLoginContext } from "../Context/UserIsLoginProvider";
import ssoService from "../Services/ssoService";
import { appData } from "../config";
import settingsIcon from "../Icons/dash.svg";
import { Link, useNavigate } from "react-router-dom";

function HomePageButton({
  toggle,
  isDropDown = false,
  isPricing = false,
  plan,
  PlanState,
  classCustom,
  isSmallScreen,
  setPlanState,
}) {
  const { authData, setAuthData } = useContext(userIsLoginContext);
  const [loading, setLoading] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [textLoader, setTextLoader] = useState(null);

  console.log(authData, "authDataauthDataauthDataauthDataauthData");

  const [buttonText, setButtonText] = useState("Start Now");

  const handleProductClick = async (app) => {
    setLoading(true);
    setSelectedApp(app);
    console.log(app);

    // setTextLoader('Redirecting to ' + app['shortName'] + ' App...') after 2 seconds
    setTimeout(() => {
      setTextLoader("Redirecting to " + app["name"] + " App...");
    }, 1000);
    //
    ssoService
      .login(app["shortName"])
      .then((res) => {
        if (res) {
          //   setRedirectUrl(res.redirect_url);
          //   redirectToUrl(res.redirect_url);
          //open res.redirect_url in new tab
          // add params to the url called wqx if plan = 1 => B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 2 => 2B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 3=> 3B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 4=> 4B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE
          // and if  PlanState == mounthly add 1 else add 2
          if (plan) {
            res.redirect_url += `&wqx=${plan}B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE${PlanState == "monthly" ? 1 : 2
              }&from_price=true`;
          }

          let newTab = window.open(res.redirect_url, "_blank");
          if (newTab) {
            newTab.focus();
          } else {
            window.location.href = res.redirect_url; // Fallback if new tab was blockedd
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    // if (url) {
    //     redirect(url);
    // }
  };

  const handleClick = () => {
    if (authData) {
      handleProductClick(appData[0]);
    } else {
      toggle(true);
    }
  };

  const redirectToUrl = (redirectUrl) => {
    if (redirectUrl) {
      const newWindow = (window.location.href = redirectUrl);
      if (newWindow) newWindow.opener = null;
    }
  };

  const nav = useNavigate();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [isSameStatus, setIsSameStatus] = useState(false);
  const [planExpired, setPlanExpired] = useState(false);

  const DateFormater = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  useEffect(() => {
    if (authData?.is_onboard) {
      // console.log("authData?.gt_user.plan_id", authData?.gt_user.plan_id);
      let plan_id = authData?.gt_user?.plan_id;

      if (authData?.gt_user?.current_subscription)
        plan_id = authData?.gt_user?.current_subscription?.plan_id;

      setCurrentPlan(plan_id);
      console.log(
        "PlanState",
        authData?.gt_user?.current_subscription?.period,
        PlanState
      );
      if (
        (authData?.gt_user?.current_subscription?.period == 1 &&
          PlanState == "monthly") ||
        (authData?.gt_user?.current_subscription?.period == 2 &&
          PlanState == "Annually") ||
        !authData?.gt_user?.current_subscription
      ) {
        setIsSameStatus(true);
        // convert to dateauth Data?.gt_user?.current_subscription?.original_expires_at  with local format

        setPlanExpired(authData?.gt_user?.subscription_date_range);
      } else {
        setIsSameStatus(false);
      }
      // setCurrentPeriod(authData?.current_subscription?.period ==1 ? "monthly" : "yearly");
    }
  }, [authData, PlanState]);

  useEffect(() => {
    console.log("authData", authData, PlanState, currentPlan, plan);
    if (authData?.is_onboard && setPlanState) {
      setPlanState(
        authData?.gt_user?.current_subscription &&
          authData?.gt_user?.current_subscription.period == 2
          ? "Annually"
          : "monthly"
      );
    }

    // setCurrentPeriod(authData?.current_subscription?.period ==1 ? "monthly" : "yearly");
  }, [authData, setPlanState]);

  return (
    <>
      {loading && <NavigateLoader text={textLoader} />}

      {isDropDown ? (
        <Link
          to=""
          onClick={handleClick}
          className={
            isSmallScreen == "SmallScreen"
              ? "flex pe-6 mb-2 border-b-2 pb-3 mt-3"
              : "flex pe-6 mb-2"
          }
        >
          <img
            src={settingsIcon}
            alt=""
            width={20}
            className={isSmallScreen == "SmallScreen" ? "me-3" : "me-2"}
          />
          <span className={isSmallScreen == "SmallScreen text-nowrap" && "fw-semibold text-nowrap"}>
            {isSmallScreen == "SmallScreen" ? "MY DASHBOARD" : "My Dashboard"}
          </span>
        </Link>
      ) : isPricing ? (
        <>
        {authData?.is_onboard ?
            currentPlan === plan ?
              <div className="text-success text-center">{planExpired}</div>
              : null
            : null
          }
          <button
            target="_blank"
            style={{
              backgroundColor:
                authData?.is_onboard && currentPlan == plan && isSameStatus
                  ? ""
                  : "",
            }}
            onClick={handleClick}
            className={
              classCustom
                ? classCustom
                : " arrowHover text-nowrap startBtn bg-[#992861] lg:py-[0.78125vw] lg:px-[1.82291666667vw] arrowHover mx-sm-5 mx-0 mt-5 mb-2"
            }
            id="FreeTrialBtn"
          >
            <span className="capitalize text-nowrap me-2">
              {authData?.is_onboard
                ? currentPlan === plan
                  ? "Manage subscription"
                  : currentPlan < plan
                    ? "Upgrade"
                    : "Downgrade"
                : "Start Now"}
            </span>
            {authData?.is_onboard ? (
              currentPlan == plan ? (
                <img
                  src="./assets/images/icons/rightchevron.svg"
                  alt="rightchevron"
                  className="lg:w-[0.44442708333vw] sm:w-[1.30208333333vw] w-[2.05128205128vw] arrowStyle flipped"
                />
              ) : (
                <>
                  <img
                    src="./assets/images/icons/rightchevron.svg"
                    alt="rightchevron"
                    className="lg:w-[0.44442708333vw]  sm:w-[1.30208333333vw] w-[2.05128205128vw] arrowStyle flipped"
                  />
                </>
              )
            ) : (
              <>
                <img
                  src="./assets/images/icons/rightchevron.svg"
                  alt="rightchevron"
                  className="lg:w-[0.44442708333vw] sm:w-[1.30208333333vw] w-[2.05128205128vw] arrowStyle flipped"
                />
              </>
            )}{" "}
          </button>

        


          {/* <p className="text-success my-4 mx-1">
            {authData?.is_onboard ? (
              currentPlan === plan ? (
                <> {planExpired}</>
              ) : currentPlan < plan ? (
                ""
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </p> */}
        </>
      ) : (
        <a
          data-aos="fade"
          data-aos-delay="400"
          onClick={() => {
            if (authData?.is_onboard) {
              handleClick();
            } else {
              nav("pricing#plans");
            }
          }}
          class="lg:mt-[3.64583333333vw] sm:mt-[5vw] mt-[8vw] startBtn arrowHover text-nowrap"
          style={{ cursor: "pointer" }}
        >
          {authData?.is_onboard ? (
            <span className="arrowHover pe-2 text-nowrap">Go to Dashboard</span>
          ) : (
            <span className="arrowHover pe-2 text-nowrap">Start Your Free Trial</span>
          )}
          <img
            src="./assets/images/icons/rightchevron.svg"
            alt="right chevron"
            class="lg:w-[0.44442708333vw] arrowStyle sm:w-[1.30208333333vw] w-[2.05128205128vw] flipped"
          />
        </a>
      )}
      
    </>
  );
}

export default HomePageButton;
