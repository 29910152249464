import React, { createContext, useEffect, useState } from "react";
import userService from "../Services/userService";

export const userIsLoginContext = createContext();

function UserIsLoginProvider({ children }) {
  const [authData, setAuthData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("auth"));
  const [showOtp, setShowOtp] = useState(false);
  const [otpToken, setOtpToken] = useState(null);

  useEffect(() => {
    if (token) {
      userService
        .getUserData()
        .then((res) => {
          if (res.success && res.data.email_verified_at !== null) {
            setAuthData(res.data);
          }
          if (res.data.email_verified_at == null) {
            // alert("Please verify your email")
            setShowOtp(true);
            setOtpToken(token);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const location = window.location.pathname;

  useEffect(() => {
    let token = localStorage.getItem("auth");
    console.log(token);

    userService
      .getUserData()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        let currentPath = window.location.pathname;

        if (currentPath !== "/checkLogin") {
          if (localStorage.getItem("auth")) {
            localStorage.removeItem("auth");

            setAuthData(null);

            window.location.href = "/";
          }
        }
      });
  }, [location]); // Add location to the dependency array to trigger effect on route change

  return (
    <userIsLoginContext.Provider
      value={{
        authData,
        setAuthData,
        setOtpToken,
        setToken,
        setShowOtp,
        token,
        showOtp,
        otpToken,
      }}
    >
      {children}
    </userIsLoginContext.Provider>
  );
}

export default UserIsLoginProvider;
