// import { LoginPage } from 'login-grandautomation';
import './LoginPage'
import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import userService from '../../../../Services/userService';
import { userIsLoginContext } from '../../../../Context/UserIsLoginProvider';
import LoginPage from './LoginPage';
import NavigateLoader from './NavigateLoader'
import toast from 'react-hot-toast';
import RegisterPage from '../Register/RegisterPage';
import OtpPage from '../Register/OtpPage';
import { HiMiniXMark } from "react-icons/hi2";
import ForgetPassowrd from '../Password/ForgetPassowrd';
import ResetPassword from '../Password/ResetPassword';


function LoginModal({ open, toggle, loading2, active, setActive }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [resetToken , setResetToken] = useState(false)

    const { authData, setAuthData, showOtp, otpToken, setShowOtp } = useContext(userIsLoginContext);
    const [token, setToken] = useState();

    const submit = async (data) => {
        // setLoading(true)


        console.log(data)
        if (data.status === "success") {
            await userService.getUserData(data.token).then((res) => {
                if (res.success) {
                    if (res.data?.email_verified_at === null) {
                        // alert("Please verify your email")
                        // setAuthData(res.data)
                        setActive('Otp')
                        setToken(data.token)
                    } else {
                        setAuthData(res.data)
                        localStorage.setItem('auth', JSON.stringify(data))
                        toast.success("Welcome back! We've missed you.")
                        toggle()
                    }
                }
            }
            )
        } else {
            toast.error("Either your email or password may be incorrect. Please verify your details and attempt to log in again.")
        }

        // const response = await fetch('https://json
    }



    useEffect(() => {
        if (showOtp && otpToken) {
            toggle()
            setActive('Otp')
            setToken(JSON.parse(otpToken).token)
        }
    }, [showOtp, otpToken])



    return (
        <>
            {loading2 ? <NavigateLoader /> :

                <Modal
                    show={open}
                    onHide={toggle}
                    aria-labelledby="example-modal-sizes-title-sm"
                    fullscreen={true}
                    className='bg-transparent'
                    keyboard={false}
                    bsPrefix='modal'
                >
                    <Modal.Body className='w-full flex flex-col p-0 position-relative modalbg'>
                        <HiMiniXMark className='text-dark bg-white ms-auto fs-4 rounded-2 cursor-pointer styleExitIcon' onClick={() => {
                            toggle();
                            setShowOtp(false);
                            setActive('Login')
                            localStorage.removeItem('auth')
                            setAuthData(null)
                        }} />
                        {/* <LoginPage submit={submit} loading={loading} /> */}
                        {active === 'Login' ? <LoginPage loading={loading} setLoading={setLoading} submit={submit} setActive={setActive} /> :
                            active === 'Otp' ? <OtpPage setActive={setActive} toggle={toggle} token={token} resetToken={resetToken}  setResetToken={setResetToken}/> : active == "forgetPassword" ?
                                <ForgetPassowrd loading={loading} setLoading={setLoading} submit={submit} setActive={setActive} setResetToken={setResetToken} /> :
                                active=="ResetPassword" ? <ResetPassword loading={loading} setLoading={setLoading} submit={submit} setActive={setActive} token={resetToken} /> :
                                <RegisterPage setActive={setActive} setToken={setToken} />}
                        {/* <LoginPage loading={loading} setLoading={setLoading} submit={submit} /> */}
                    </Modal.Body>
                </Modal>
            }


        </>
    );
}

export default LoginModal;