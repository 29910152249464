import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { appData, ga } from "../../../../config";

import logo from '../../../../images/ProductsLogo/DarkBg/Frame.svg'
import toast from "react-hot-toast";
function ForgetPassowrd({ submit, app, loading, setLoading, redirect = false, toggle, open, setActive, setResetToken }) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("this is an inavlid email")

    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      // send request to the server
      // if successfull
      // store the token in local storage
      // redirect to the dashboard
      // if failed
      // show error message
      setLoading(true);
      const data = {
        email: values.email.toString()
      };



      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      // submit the data
      fetch("https://api.grandautomation.io/api/reset-password", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data, "data");
          // console.log(data.data.status, "token");

          if (data.success) {
            toast.success("Password reset otp has been sent successfully")

            let randomToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            let email = values.email.toString()



            let tokenMap = {
              email: email,
              token: randomToken
            }

            let expiry = new Date().getTime() + 3600000
            let tokenMapString = JSON.stringify(tokenMap)
            sessionStorage.setItem('tokenMap', tokenMapString, expiry)
            setLoading(false)
            setResetToken(randomToken)
            // add token to params url wuthout navigate
            setActive('Otp')

          } else {
            setError(data.error.message);
            submit({ status: "error", message: data.error.message })
          }
        })
        .catch((error) => {

          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          // submit(data);
        });
    },
  });

  return (

    <div className=" flex items-center justify-center flex-1 ">
      <div className="">
        {/* 
        {error && (
          <div
            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">{error}</span>
          </div>
        )}
        {success && (
          <div
            className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span class="font-medium">{success}</span>
          </div>
        )} */}
        <form onSubmit={formik.handleSubmit} className="text-white  m-auto w-100">

          <div className="flex justify-content-between mb-4">


            <h5 className="me-4 w-100" style={{
              width: "120px"
            }}>Reset Your Password</h5>


            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "12rem" , marginBottom:'10px' }}
                className="logoResponsive"
              />
            </div>

          </div>

          <p>If your email address matches an account in our records, we will send a password reset link to your inbox</p>


          <div className="">
            <div className="w-full mt-10">
              <div className="flex">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 me-1"
                >
                  Email Address
                </label>
                <span className="starColor">*</span>
              </div>
              <div className="mt-2 relative">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className={formik.errors.email && formik.touched.email ? "loginInputsInValid" : "loginInputsValid"}
                  placeholder="Enter Your Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              {formik.errors.email && formik.touched.email ? (
                <div
                  style={{
                    marginTop: "0.2rem",
                    color: "#f80000",
                    fontSize: "0.875rem",
                  }}
                >
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-4">

            <div className="flex items-center mt-4">
              <button
                disabled={loading}
                type="submit"
                style={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#4c4c4c",
                  borderRadius: "0.5rem",
                  fontSize: "0.875rem",
                  padding: "0.625rem",
                  textAlign: "center",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>


            {/* <div className="text-sm mt-3">
              <Link to={''} 
                className="font-semibold underline">
                Login
              </Link>
            </div> */}
          </div>
        </form>
      </div>
    </div>

  );
}

export default ForgetPassowrd;