import React, { useContext, useState } from "react";
import { Navbar, Toast } from "flowbite-react";
import logo from "../../Icons/navbarLogo.svg";
import logoSidebar from "../../images/Logo01.png";
import { Link, useNavigate } from "react-router-dom";
import SidePanelIcon from "../../Icons/icon-park-outline_application-menu.svg";
import SupportIcon from "../../Icons/radix-icons_external-link.svg";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "animate.css";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Import products images
import TransferProduct from "../../images/ProductsLogo/whiteBG/TransferWhiteBg.svg";
import Calender from "../../images/ProductsLogo/whiteBG/calenderWhiteBg.svg";
import Form from "../../images/ProductsLogo/whiteBG/Property 1=G-Form.svg";
import WorkFlow from "../../images/ProductsLogo/whiteBG/Property 1=G-Workflow.svg";
import SupportTick from "../../images/ProductsLogo/whiteBG/Property 1=G-Support Tick.svg";
import HRM from "../../images/ProductsLogo/whiteBG/Property 1=G-HRM.svg";
import Signature from "../../images/ProductsLogo/whiteBG/Property 1=G-Signature.svg";
import Accounting from "../../images/ProductsLogo/whiteBG/Property 1=G-Accounting.svg";
import Projects from "../../images/ProductsLogo/whiteBG/Property 1=G-Projects.svg";
import Forms from "../../images/ProductsLogo/whiteBG/Property 1=G-Forms.svg";
import Inventory from "../../images/ProductsLogo/whiteBG/Property 1=G-Inventory.svg";
import VideoConference from "../../images/ProductsLogo/whiteBG/Property 1=G-Video Conference.svg";

// import icons
import settingsIcon from "../../Icons/settings icon.svg";
import logoutIcon from "../../Icons/logout icon.svg";
import ProfileIcon from "../../Icons/solar_user-bold-duotone.svg";
import LoginModal from "./Modals/Login/LoginModal";
import { userIsLoginContext } from "../../Context/UserIsLoginProvider";
import { appData, ga } from "../../config";
import toast from "react-hot-toast";
import { IoPricetagsOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";

import Offcanvas from "react-bootstrap/Offcanvas";
import RegisterModal from "./Modals/Register/RegisterModal";
import ssoService from "../../Services/ssoService";
import axios from "axios";
import userService from "../../Services/userService";
import HomePageButton from "../HomePageButton";

import { CgMenu } from "react-icons/cg";
import { HiMiniXMark } from "react-icons/hi2";

function NavbarComponent({ open, toggle, setActive, active }) {
  // products logos
  const products = [
    { image: TransferProduct, app: appData[0], available: true },
    { image: Calender, app: appData[1] },
    { image: Form, app: appData[2] },
    { image: WorkFlow, app: appData[3] },
    { image: SupportTick, app: appData[4] },
    { image: HRM, app: appData[5] },
    { image: Signature, app: appData[6] },
    { image: Accounting, app: appData[7] },
    { image: Projects, app: appData[8] },
    { image: Forms, app: appData[9] },
    { image: Inventory, app: appData[10] },
    { image: VideoConference, app: appData[11] },
  ];

  // offcanvas
  const [show, setShow] = useState(false);

  const handleCloseOffcanvas = () => setShow(false);
  const handleShow = () => setShow(true);

  // dropDown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDropdown = Boolean(anchorEl);
  const id = openDropdown ? "simple-popover" : undefined;

  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const toggleRegisterModal = () => setOpenRegisterModal(!openRegisterModal);

  const {
    authData,
    setAuthData,
    setShowOtp,
    setOtpToken,
    showOtp,
    otpToken,
    setToken,
  } = useContext(userIsLoginContext);
  const RemoveToken = async () => {
    await ssoService
      .logout()
      .then((res) => {
        localStorage.removeItem("auth");
        toast.success("You 're now logged out. See you soon");
        setAuthData(null);
      })
      .catch((error) => {
        console.log(error);
      });
    // const targetIframe = document.getElementById('targetIframe').contentWindow;
    // targetIframe.postMessage('logout', 'https://g...content-available-to-author-only...l.app/');
  };

  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const openNewWindow = async () => {
    setLoading(true);
    const iframeUrl = `${ga.GA_APP}/checkLogin`;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const windowWidth = 600; // Width of the new window
    const windowHeight = 400; // Height of the new window

    const left = Math.max(0, (screenWidth - windowWidth) / 2);
    const top = Math.max(0, (screenHeight - windowHeight) / 2);

    const newWindow = window.open(
      iframeUrl,
      "winname",
      `toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=${left},top=${top},width=${windowWidth},height=${windowHeight}`
    );

    try {
      newWindow.document.write("<html><body></body></html>");

      // Set window location to the URL
      newWindow.location.href = iframeUrl;

      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        // Fallback if new tab was blocked
        window.location.href = iframeUrl + "?redirect=true";
      }

      // Set window location to the URL
      setTimeout(() => {
        newWindow.close();
        if (localStorage.getItem("auth")) {
          // alert('ok')
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + JSON.parse(localStorage.getItem("auth")).token;
          // setAuthData(JSON.parse(localStorage.getItem("auth")))
          userService
            .getUserData()
            .then((res) => {
              if (res.success && res.data.email_verified_at !== null) {
                setAuthData(res.data);
                setSuccess("Login Successful! Redirecting to Dashboard...");
                toast.success("Welcome back! We've missed you.");
              } else {
                toast.error("Please verify your email");
                setShowOtp(true);
                setOtpToken(localStorage.getItem("auth"));
              }
            })
            .catch((error) => {
              console.log(error);
            });
          // add token to header for axios
        } else {
          toggle();
        }
      }, 2500); // Adjust the delay if needed
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } catch (error) {
      toggle();
    }

    // Check if the new window is valid
  };

  // navLinks
  const [openNavLinks, setOpenNavLinks] = useState(false);

  return (
    <>
      <header className="z-[12] fixed w-full top-0 left-0 lg:px-[5.20833333333vw] lg:py-[1.2vw] sm:p-[4vw] p-[6vw] py-0  px-10 test">
        <div className="flex justify-between items-center">
          <div className="m-auto w-full flex items-center navbar py-3">
            <Link to="/" className="">
              <img
                src={logo}
                className=" h-11 sm:h-10"
                alt="Flowbite React Logo"
              />
            </Link>
            <div className="d-flex">
              <div className="d-sm-flex d-none">
                <Link to="/pricing#plans" className="font flex items-center me-4">
                  PRICING
                </Link>

                <Link to="FAQs" className="font flex items-center me-4">
                  FAQs
                </Link>

                <a
                  href={ga.SUPPORT}
                  className="font flex items-center me-4"
                  target="_blank"
                >
                  SUPPORT
                  <img src={SupportIcon} alt="" className="ms-3 supportIcon" />
                </a>
                {!authData ? (
                  <>
                    <Link
                      to="/"
                      onClick={(event) => {
                        event.preventDefault();
                        setLoading(true);
                        setActive("Register");
                        openNewWindow();
                      }}
                      className="font flex items-center me-4"
                    >
                      SIGN UP
                    </Link>
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        setLoading(true);
                        setActive("Login");
                        openNewWindow();
                      }}
                      className="font flex items-center me-4"
                    >
                      LOGIN
                    </Link>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        authData?.profile_photo_url
                          ? authData?.profile_photo_url
                          : ProfileIcon
                      }
                      alt=""
                      aria-haspopup="true"
                      onClick={handleClick}
                      className={
                        authData?.profile_photo_url
                          ? "cursor-pointer rounded-full object-cover w-7 h-7 me-3"
                          : "cursor-pointer rounded-full object-cover w-7 h-7 me-3 p-0.5 border-2 border-gray-200"
                      }
                    />
                  </>
                )}

              </div>
              <div className="d-flex align-items-center">
                <div className=" cursor-pointer">
                  <img
                    src={SidePanelIcon}
                    alt=""
                    className="me-sm-0 me-4 object-fit-contain"
                    onClick={handleShow}
                  />
                </div>
                <div className="d-flex d-sm-none items-center cursor-pointer">
                  {authData && (
                    <img
                      src={SidePanelIcon}
                      alt=""
                      className="me-3"
                      id="fade-button"
                      onClick={handleShow}
                    />
                  )}
                  <CgMenu
                    className=""
                    style={{ fontSize: "27px", color: "#6b7280" }}
                    onClick={() => {
                      setOpenNavLinks(true);
                      console.log("kkk");
                    }}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </header>
      <LoginModal
        open={open}
        toggle={toggle}
        loading2={loading}
        active={active}
        setActive={setActive}
      />
      {/* <RegisterModal open={openRegisterModal} toggle={toggleRegisterModal} /> */}
      {/* <LoginModal open={open} toggle={toggle} loading2={loading} /> */}

      {/* dropDown Menu  */}

      <div>
        <Popover
          id={id}
          open={openDropdown}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            marginTop: 1,
            "& .MuiPaper-root": { outline: "none" }, // Adding style to remove outline
          }}
        >
          <Typography>
            <div className=" text-sm p-3 pe-4">
              {/* welcome name */}
              <div className=" ms-1 mb-3">
                <p
                  className="pe-6 mb-2 me-3 font-size-4 font-medium"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Welcome,{" "}
                  {
                    // first 11 characters of the name
                    authData?.name?.length > 11
                      ? authData?.name?.substring(0, 11) + "..."
                      : authData?.name
                  }
                  !
                </p>

                <p className="text-gray-500	">{authData?.email}</p>
              </div>
              {authData?.is_onboard ? (
                <HomePageButton isDropDown={true} />
              ) : null}
              <div className="border-b-2 border-gray-200 my-3"></div>
              <div className="mt-3">
                <Link
                  to={ga.GA_APP + "/Account-Setting"}
                  className="flex pe-6 mb-1"
                  onClick={handleClose}
                  target="_blank"
                >
                  <img src={settingsIcon} alt="" className="me-2" />
                  <span>Account Settings</span>
                </Link>
                <div
                  className="flex cursor-pointer mt-3"
                  onClick={() => {
                    RemoveToken();
                    handleClose();
                  }}
                >
                  <img src={logoutIcon} alt="" className="me-2" />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </Typography>
        </Popover>
      </div>

      {/* offcanvas */}

      <Offcanvas
        show={show}
        onHide={handleCloseOffcanvas}
        placement="end"
        className="rounded-lg "
      >
        <Offcanvas.Header className="flex justify-between p-4">
          <HiMiniXMark
            className="fs-3 cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
          />
          <img src={logoSidebar} alt="" width={90} />
        </Offcanvas.Header>
        <Offcanvas.Body className="rounded d-flex flex-col justify-content-between p-4 pt-0">
          <div className="flex flex-wrap justify-center my-7">
            {products.map((product, index) => (
              <img
                key={index}
                src={product.image}
                alt=""
                className={
                  product?.available // Only set cursor-pointer if available
                    ? "cursor-pointer px-2 productLogosMenu"
                    : "opacity-50 cursor-default px-2 productLogosMenu" // Indicate unavailable with opacity and cursor
                }
                onClick={
                  product?.available
                    ? () => {
                      window.open(product.app.url, "_blank");
                    }
                    : null
                } // Prevent click if unavailable
              />
            ))}
          </div>

          <div>
            <p className="mb-4 font-[350]">
              We try to provide a unique experience to meet your business needs.
              So feel free to contact us to hear suggestions
            </p>
            <a
              href="mailto:support@grandautomation.io"
              className="bg-black text-white font-semibold w-full text-center rounded-md py-2 inline-block text-decoration-none"
            >
              Contact Us
            </a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* navLinks */}

      <Offcanvas
        show={openNavLinks}
        onHide={setOpenNavLinks}
        placement="end"
        className="rounded-lg"
      >
        <Offcanvas.Header className="px-4">
          <div className="d-flex justify-content-between w-100">
            <HiMiniXMark
              className="fs-2"
              onClick={() => {
                setOpenNavLinks(false);
              }}
            />
            <img src={logoSidebar} alt="" width={90} />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="rounded px-4 flex flex-col justify-content-between">
          <div className="">
            {authData ? (
              <div className="fontSize">
                {/* welcome name */}
                <p className="pe-6 me-3 fs-5 font-bold mb-1">
                  Welcome,{" "}
                  {
                    // first 11 characters of the name
                    authData?.name?.length > 20
                      ? authData?.name?.substring(0, 20) + "..."
                      : authData?.name
                  }
                  !
                </p>

                <p
                  className="fw-bold fs-6 border-b border-dark pb-3 mb-5"
                  style={{ color: "#575757" }}
                >
                  {authData?.email}
                </p>
                <div className="">
                  <Link
                    to={ga.GA_APP + "/Account-Setting"}
                    className="flex pe-6 mb-1 border-b-2  pb-3 mt-3 fw-semibold"
                    onClick={handleClose}
                    target="_blank"
                  >
                    <img src={settingsIcon} alt="" className="me-3" />
                    <span>ACCOUNT SETTINGS</span>
                  </Link>

                  {authData?.is_onboard ? (
                    <HomePageButton
                      isDropDown={true}
                      isSmallScreen={"SmallScreen"}
                    />
                  ) : null}

                  <div
                    className="flex cursor-pointer mt-3 border-b-2 pb-3 fw-semibold"
                    onClick={() => {
                      RemoveToken();
                      handleClose();
                    }}
                  >
                    <img src={logoutIcon} alt="" className="me-3" />
                    <span>LOGOUT</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="fontSize mt-4">
                <Link
                  to="/pricing#plans"
                  className="font flex items-center border-b-2 pb-3 mt-3 fontSize"
                  onClick={() => {
                    setOpenNavLinks(false);
                  }}
                >
                  {/* <IoPricetagsOutline className='fs-6 me-3' style={{ color: '#d1d4d7' }} /> */}
                  PRICING
                </Link>

                <Link
                  to="FAQs"
                  className="font flex items-center border-b-2 pb-3 mt-3 fontSize"
                  onClick={() => {
                    setOpenNavLinks(false);
                  }}
                >
                  {/* <GoQuestion className='fs-6 me-3' style={{ color: '#d1d4d7' }} /> */}
                  FAQs
                </Link>

                <a
                  href="https://s...content-available-to-author-only...n.io/login"
                  className="font flex items-center border-b-2  pb-3 mt-3 fontSize"
                  onClick={() => {
                    setOpenNavLinks(false);
                  }}
                  target="_blank"
                >
                  {/* <img src={SupportIcon} alt="" className='me-3 supportIcon' /> */}
                  SUPPORT
                </a>

                <Link
                  to="/"
                  onClick={(event) => {
                    event.preventDefault();
                    setLoading(true);
                    setActive("Register");
                    openNewWindow();
                    setOpenNavLinks(false);
                  }}
                  className=" flex items-center border-b-2 fw-semibold pb-3 mt-3"
                >
                  SIGN UP
                </Link>
                <Link
                  to=""
                  onClick={(event) => {
                    event.preventDefault();
                    setLoading(true);
                    setActive("Login");
                    openNewWindow();
                    setOpenNavLinks(false);
                  }}
                  className=" flex items-center border-b-2 fw-semibold pb-3 mt-3"
                >
                  LOGIN
                </Link>
              </div>
            )}
          </div>
          <button className="bg-dark text-white py-2 font-bold rounded">
            Contact Us
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavbarComponent;
